import { AudiFootnoteEngineProps } from '../components/audi-footnote-engine/audi-footnote-engine';
import { Footnote } from '../types/footnotes-response.type';

export const referenceSelector = '.audi-j-footnote-reference';
const footnotesAnchorTextSelector = '.audi-footnote-anchor__text';

export type LayerElementOptions = Pick<AudiFootnoteEngineProps, 'layerElementClassName'>;

export function findAllPropsInElement({
  element,
  prop,
  querySelector,
  filter
}: {
  element: HTMLElement | null;
  prop: string;
  querySelector: string;
  filter?: (array: unknown[]) => string[];
}): unknown[] {
  const list: unknown[] = [];
  element?.querySelectorAll(querySelector).forEach((domElement) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (domElement[prop]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      list.push(domElement[prop]);
    }
  });
  if (filter) {
    return filter(list);
  }
  return list;
}

export const filterArrayOfObjectsForKey =
  (attribute: string) =>
  (giveArray: { [keyValue: string]: string }[]): string[] => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return giveArray.reduce((accumulator, current) => {
      const value = current[attribute];
      if (value) {
        return [...accumulator, value];
      }
      return accumulator;
    }, []);
  };

export function extractFootnoteId(rawKey_: string): string {
  let key = rawKey_;
  const index = key.indexOf('#');

  if (index !== -1) {
    key = key.substr(index + 1);
  }

  return key;
}

export function replaceTextWithFootnoteNumber(element_: HTMLElement, number_: number): void {
  if (!element_.dataset.fixedText && number_ > 0) {
    const textContainer = element_.querySelector(footnotesAnchorTextSelector);
    if (textContainer) {
      textContainer.innerHTML = `${number_}`;
    }
  }
}

export function setDataset({
  element_,
  name,
  id,
  referenceId
}: {
  element_: HTMLLinkElement;
  name: string;
  id: string;
  referenceId: string;
}): void {
  // eslint-disable-next-line no-param-reassign
  element_.href = `#${id}`;
  // eslint-disable-next-line no-param-reassign
  element_.dataset[name] = referenceId;
}

export function findFootnoteReferences(domContext_: HTMLElement): NodeListOf<HTMLLinkElement> {
  return domContext_.querySelectorAll(referenceSelector);
}

export function findFootnoteReferencesOutsideOfFapps(domContext_: HTMLElement): HTMLLinkElement[] {
  const domNodes = findFootnoteReferences(domContext_);
  const filterList = [] as HTMLLinkElement[];
  domNodes.forEach((reference) => {
    const closest = reference.closest('feature-app, div[data-one-layer="true"]') as HTMLElement;
    if (
      closest === null ||
      (closest && closest.dataset && closest.dataset.fefaLayerActive === 'true')
    ) {
      filterList.push(reference);
    }
  });
  return filterList;
}

/**
 * getContext - checks and returns in what context we are - layer / modal layer / page
 * @returns {HTMLElement}
 */
export function getContext(
  element: Element | null,
  options?: LayerElementOptions
): HTMLElement | null {
  const layerElementClassName = options?.layerElementClassName;

  if (layerElementClassName && element?.closest(`.${layerElementClassName}`)) {
    return element.closest(`.${layerElementClassName}`);
  }

  if (element?.closest('.modal-layer')) {
    return element.closest('.modal-layer');
  }

  if (element?.closest('.nm-layer-wrapper')) {
    return element.closest('.nm-layer-wrapper');
  }

  return document.body;
}

export function getLayers(): NodeListOf<HTMLElement> {
  return document.querySelectorAll('.nm-layer-wrapper, .modal-layer, [data-fefa-layer-active]');
}

/**
 * getRequiredOffset - adaption offset for scrolling position
 * @param {HTMLElement} element_ - target element
 * @returns {Number} offset
 */
export function getRequiredOffset(element_: Element): number {
  const defaultOffset = 120; // arbitrary value
  const stickyElementsIncreasedOffset = 30; // arbitrary value

  let stickyNavigationOffset = 0;
  let stickyAnchorNavigationOffset = 0;

  if (getContext(element_) === document.body && document.body.classList.contains('nm-nav-fixed')) {
    const inpageNavigation = document.querySelector('.nm-nav-wrap');
    if (inpageNavigation) {
      stickyNavigationOffset = inpageNavigation.clientHeight + stickyElementsIncreasedOffset;
    }
    if (document.body.classList.contains('nm-has-current-consumption')) {
      const currentConsumptionModule = document.querySelector('.nm-module-current-consumption');
      if (currentConsumptionModule) {
        stickyNavigationOffset += currentConsumptionModule.clientHeight;
      }
    }
  }

  const anchorNavigationList = document.querySelector('.nm-anchor-navigation-list');
  if (getContext(element_) === document.body && !!anchorNavigationList) {
    const stickyAnchorAttribute = anchorNavigationList.getAttribute('is-sticky');
    if (stickyAnchorAttribute === 'true') {
      stickyAnchorNavigationOffset =
        anchorNavigationList.clientHeight + stickyElementsIncreasedOffset;
    }
  }

  return defaultOffset + stickyNavigationOffset + stickyAnchorNavigationOffset;
}

/**
 * _handleContentUpdateDefaultLayerCondition
 * condition function for default LAYER Case (function only required for testing)
 * @returns {boolean} true if Layer is Visible
 */
export function isLayerVisible(): boolean {
  return (
    !!document.querySelector('.nm-layer-visible') ||
    !!document.querySelector('[data-layer-active=true]') ||
    !!document.querySelector('[data-fefa-layer-active=true]')
  );
}

export const sortArrayIfValueIsUndefined =
  (value: string) =>
  (arrayInput: unknown[]): unknown[] => {
    return arrayInput.sort((a, b) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const aX = !a[value] ? -1 : a[value];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const bX = !b[value] ? -1 : b[value];
      return aX - bX;
    });
  };

export function checkIsFefaInLayer(
  element: HTMLElement | null,
  options?: LayerElementOptions
): boolean {
  const layerElementClassName = options?.layerElementClassName;

  let condition = false;
  if (layerElementClassName && element?.closest(`.${layerElementClassName}`)) {
    condition = !!element.closest(`.${layerElementClassName}`);
  } else if (element?.closest('.modal-layer')) {
    condition = !!element?.closest('.modal-layer');
  } else if (element?.closest('.nm-layer-wrapper')) {
    condition = !!element?.closest('.nm-layer-wrapper');
  }
  return condition;
}

export function filterReferencesToAdd(
  allFootnoteIDs_: string[],
  data_: { id: string; number?: number }[]
): string[] {
  const referencesToAdd: string[] = [];

  data_.forEach((footnote) => {
    const footnoteID = extractFootnoteId(footnote.id);
    if (!allFootnoteIDs_.includes(footnoteID)) {
      referencesToAdd.push(footnoteID);
    }
  });

  return referencesToAdd;
}

// eslint-disable-next-line max-len
export function sortFootnotesForDisplay(
  filteredFootnotesToDisplay: Footnote[]
): Footnote[] | undefined {
  return filteredFootnotesToDisplay.sort((a, b) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return a.position - b.position;
  });
}

export function removeDuplicates(footnoteIDsToDisplay: string[] | undefined): string[] {
  return Array.from(new Set(footnoteIDsToDisplay));
}

export function createReferenceList(namedReferences?: string[], referenceNumber?: string): string {
  const allReferences = referenceNumber
    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [`${referenceNumber}`].concat(namedReferences)
    : namedReferences;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return allReferences.join(', ');
}

export const createHashForGivenString = (s: string): string => {
  const hashedNumber = s.split('').reduce((a, b) => {
    // eslint-disable-next-line no-bitwise,no-param-reassign
    a = (a << 5) - a + b.charCodeAt(0);
    // eslint-disable-next-line no-bitwise
    return a & a;
  }, 0);
  return hashedNumber.toString();
};
